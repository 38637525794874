import { FIELDS } from '../../../../../../constants/roles'
import { GeneralRadioGroup } from './general-radio-group'
import { FormsFieldPreset } from '@wix/forms-common'

// in use for payment form items list state (adding this field with the items the user given)
export class GeneralItemsList extends GeneralRadioGroup {
  public get role() {
    return FIELDS.ROLE_FIELD_ITEMS_LIST
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_ITEMS_LIST
  }

  public get base() {
    return GeneralItemsList.prototype
  }

  protected get data() {
    return {
      type: 'RadioGroup',
      label: this.translate(`preset.${this.fieldType}Label`),
      options: [],
    } as any
  }
}
